import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {ExportDTO} from '../../model/dto/export.dto';
import {ExportTypeEnum} from '../../model/enums/export-type.enum';
import {ReportingDto} from '../../model/dto/reporting.dto';
import {OccurrencePdfDTO} from '../../model/dto/occurrence-pdf.dto';
import {SimpleInterviewDTO} from '../../model/dto/simple-interview.dto';
import {SignatureVisitModel} from '../../model/event/signature-visit.model';
import {getMobileToken} from 'src/app/utils/utils.static';

@Injectable({
  providedIn: 'root'
})
export class ExportWebservice {
  private readonly root = `${environment.envApiUrl}/exports`;

  private readonly httpMobile: HttpClient;

  constructor(private readonly http: HttpClient, private readonly httpBackend: HttpBackend) {
    this.httpMobile = new HttpClient(httpBackend);
  }

  // Recupere l'export sur l'api
  getExport(exp: ExportDTO) {
    return this.http.post(`${this.root}`, exp, {responseType: 'blob'});
  }

  postExportReporting(rep: ReportingDto) {
    return this.http.post(`${this.root}/reporting`, rep, {responseType: 'blob'});
  }

  postExportPresence(occurrencePdf: OccurrencePdfDTO) {
    return this.http.post(`${this.root}/pdf`, occurrencePdf, {responseType: 'blob'});
  }

  postExportInterview(simpleInterviewDtoList: SimpleInterviewDTO[]) {
    return this.http.post(`${this.root}/pdfInterview`, simpleInterviewDtoList, {
      responseType: 'blob'
    });
  }

  // Renvoie l'enumeration a l'index demande
  getExportType(index) {
    switch (index) {
      case 0:
        return ExportTypeEnum.EXPORT_NB_JEUNE_INSCRIT;
      case 1:
        return ExportTypeEnum.EXPORT_GLOBAL_JEUNE;
      case 2:
        return ExportTypeEnum.EXPORT_NOMBRE_MOYEN_JEUNE;
      case 3:
        return ExportTypeEnum.EXPORT_GLOBAL_INSCRIPTION;
      case 4:
        return ExportTypeEnum.EXPORT_NOMBRE_JEUNE_NON_PRESENT;
      case 5:
        return ExportTypeEnum.EXPORT_NOMBRE_INSCRIPTION_SUPPRIME;
      default:
        return null;
    }
  }

  getMailRi() {
    return this.http.get(`${environment.envApiUrl}/Test/email`);
  }

  printPDFVisite(visitId: number) {
    const token = getMobileToken();
    if (token) {
      return this.httpMobile.get(`${this.root}/visite-pdf/${visitId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        responseType: 'blob'
      });
    } else {
      return this.http.get(`${this.root}/visite-pdf/${visitId}`, {responseType: 'blob'});
    }
  }

  signPDFVisite(visitId: number, signatures: SignatureVisitModel) {
    return this.http.post(`${this.root}/sign-visite-pdf/${visitId}`, signatures);
  }
}
